<template>
    <Editor :url="url" :fields="fields" :filters="filters" icon="mdi-map"
            testo-list="Lista regioni"
            testo-insert="Nuova regione"
            testo-update="Modifica regione"
            testo-delete="Vuoi cancellare la regione?">
        <template #form="{instance}">
            <v-text-field v-model="instance.codice" label="Codice"></v-text-field>
            <v-text-field v-model="instance.descrizione" label="Descrizione"></v-text-field>
        </template>
    </Editor>
</template>


<script>
import Editor from "@/components/Editor";

export default {
    data: () => ({
        fields: [
            {text: 'Codice', value: 'codice'},
            {text: 'Nome', value: 'descrizione'},
        ],
        filters: [
            {field: 'descrizione', label: 'Descrizione'},
        ],
    }),
    computed: {
        url() {
            return '/backend/regione/';
        }
    },

    components: {
        Editor
    },
}
</script>